import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { SubSink } from 'subsink';
import { CallToActionDialogComponent } from '../../shared/call-to-action-dialog/call-to-action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable, combineLatest, finalize, firstValueFrom, forkJoin, of, switchMap, tap } from 'rxjs';
import { ModuleService } from './module.service';
import { IELearningMetadata, IMasterclass, IMasterclassVersion, IModule, IUser } from '@shared';
import { MasterclassService } from '../masterclasses/masterclass.service';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss'],
})
export class ModulesComponent implements OnInit {

  masterclass: IMasterclass = null;
  masterclassVersion$: Observable<IMasterclassVersion>;
  selectedModule: IModule = null;
  isModuleOverviewOpen = false;
  subs = new SubSink();

  loadingProgress = true;

  innerWidth = window.innerWidth;

  baseUrl = '';
  demo = false;

  currentUser: IUser;

  stripePromise = loadStripe(environment.stripe_key);
  loadingStripe: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private firebaseService: FirebaseService, private dialog: MatDialog, private moduleService: ModuleService, private masterclassService: MasterclassService) {
    const urlSplit = this.router.url.split('/');
    const masterclassId = urlSplit[urlSplit.indexOf('masterclasses') + 1];
    this.baseUrl = this.router.url.split(masterclassId)[0];

    this.subs.sink = activatedRoute.url.subscribe(() => {
      this.demo = activatedRoute.snapshot.data.demo;
    });

    if (urlSplit.includes('visualize')) {
      const versionId = urlSplit[urlSplit.indexOf('visualize') + 1];
      this.masterclassVersion$ = this.masterclassService.getMasterclassVersion(versionId);
      this.subs.sink = this.masterclassVersion$.pipe(finalize(() => this.scrollIntoView())).subscribe((masterclassVersion) => {
        this.masterclass = masterclassVersion.masterclasses.find((mc) => mc.id === masterclassId);
        this.loadModules();
      });
    } else {
      const masterclassId = urlSplit[urlSplit.indexOf('masterclasses') + 1];
      this.subs.sink = combineLatest([this.firebaseService.getRemoteConfig(), this.masterclassService.getObtainedMasterclasses$()]).pipe(switchMap(([remoteConfig, obtainedMasterclasses]) => {
        const selectedELearningVersion = remoteConfig.parameters['selectedELearningVersion'].defaultValue.value;
        this.masterclassVersion$ = this.masterclassService.getMasterclassVersion(selectedELearningVersion).pipe(tap((mcv) => {
          if (!(mcv.id === remoteConfig.parameters['selectedELearningVersion'].defaultValue.value)) {
            this.router.navigate(['home']);
          }
          this.masterclass = mcv.masterclasses.find((mc) => mc.id === masterclassId);
          this.masterclass.isObtained = obtainedMasterclasses.includes(this.masterclass.id);
          if (!obtainedMasterclasses.includes(this.masterclass.id) && !this.masterclass.modules.some((module: IModule) => module.isAvailableInDemo)) {
            this.router.navigate(['home']);
          }
          this.loadModules();
        }));
        return this.masterclassVersion$;
      })).subscribe();
    }
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await firstValueFrom(this.firebaseService.getCurrentUserProperties());
  }

  loadModules(): void {
    this.selectedModule = this.masterclass.modules[0];

    if (!this.masterclass.modules.length) {
      this.router.navigate([''], { relativeTo: this.activatedRoute.parent })
    }

    this.masterclass.modules.forEach((module) => {
      this.moduleService.getCompletedCoursesProgress$(module).subscribe((progress) => {
        if (progress) {
          module.progress = progress;
        }
      });
      setTimeout(() => {
        this.loadingProgress = false;
        this.scrollIntoView();
      }, 500)
    });
  }

  scrollIntoView(): void {
    if (window.innerWidth > 768) {
      const div = document.getElementById("module-thumbnails");
      div.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }

  onModuleClick(module: IModule): void {
    if (!module.isAvailableInDemo) {
      if (this.demo) {
        this.openSubscribeCTADialog();
      } else if (!this.masterclass.isBuyable && !this.masterclass.isObtained) {
        this.openContactUsMasterclassDialog();
      } else if (!this.masterclass.isObtained) {
        this.buyMasterclass(this.masterclass);
      } else {
        this.router.navigate([module.id], { relativeTo: this.activatedRoute.parent });
      }
    }
    else {
      this.router.navigate([module.id], { relativeTo: this.activatedRoute.parent });
    }
  }

  onThumbnailClick(module: IModule): void {
    this.selectedModule = module;
    this.isModuleOverviewOpen = true;
  }

  closeModuleOverview(): void {
    this.isModuleOverviewOpen = false;
  }

  navigateToELearning(): void {
    this.router.navigate([this.baseUrl]);
  }

  async buyMasterclass(masterclass: IMasterclass): Promise<void> {
    if (this.currentUser) {
      this.loadingStripe = true;
      const stripe = await this.stripePromise;
      const user = await firstValueFrom(this.firebaseService.getCurrentUserProperties());

      const metadata: IELearningMetadata = {
        userId: user.id,
        masterclassId: masterclass.id,
        masterclassName: masterclass.name
      }

      const sessionId = await firstValueFrom(this.masterclassService.createCheckoutSession(metadata));

      const { error } = await stripe!.redirectToCheckout({
        sessionId: sessionId
      });
      if (error) {
        console.error(error);
      }
    } else {
      this.openSubscribeCTADialog();
    }
  }

  openSubscribeCTADialog(): void {
    this.dialog.open(CallToActionDialogComponent, {
      width: '250px',
      panelClass: 'call-to-action-dialog',
      data: { title: 'Pas encore parmi nous ?', text: "Créez vous un compte gratuitement ou connectez-vous afin de souscrire à nos formations !", actionText: "S'inscrire >>", actionUrl: '/profile/signup', redirectionAfterAction: '/e-learning' }
    });
  }

  openContactUsMasterclassDialog(): void {
    this.dialog.open(CallToActionDialogComponent, {
      width: '250px',
      panelClass: 'call-to-action-dialog',
      data: { title: 'Vous avez aimé la démo ?', text: "N'attendez plus et contactez-nous pour demander votre accès et booster votre visibilité !", actionText: 'Nous contacter >>', actionExtUrl: 'https://mymusicads.com/nous-contacter', redirectionAfterAction: '/e-learning', hideLogin: !!this.currentUser }
    });
  }
}
