import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ELearningElementDialogOperation, ELearningElementInfo, ELearningElementType, IELearningElement } from '../../models/ELearningElement';
import { BackOfficeELearningService } from '../back-office-e-learning.service';
import { ICourse, IMasterclass, IModule, IQuestion } from '@shared';

@Component({
  selector: 'app-e-learning-element',
  templateUrl: './e-learning-element.component.html',
  styleUrls: ['./e-learning-element.component.scss']
})
export class ELearningElementComponent implements OnInit {
  @Input() elementInfo: ELearningElementInfo;
  @Output() elementInfoChange: EventEmitter<IELearningElement> = new EventEmitter();
  @Input() elementType: ELearningElementType;
  @Output() deleteElement: EventEmitter<IELearningElement> = new EventEmitter();
  @Output() createNewElement: EventEmitter<IELearningElement> = new EventEmitter();
  @Output() openNewElement: EventEmitter<IELearningElement> = new EventEmitter();
  @Output() changeIndex: EventEmitter<number> = new EventEmitter();

  elementImageSrc = '';

  mcq: IQuestion = {
    mandatory: false,
    id: 'thisIsItQuestion',
    question: 'thiIsIt',
    questionType: 'multipleChoice',
    propositions: [
      {
        proposition: 'aa',
        isCorrect: true
      },
      {
        proposition: 'bb',
        isCorrect: false
      },
      {
        proposition: 'cc',
        isCorrect: true
      }
    ]
  }

  constructor(private dialog: MatDialog, public backOfficeELearningService: BackOfficeELearningService) {}

  ngOnInit(): void {
    this.elementImageSrc = this.getElementImageSrc();
  }

  getElementImageSrc() {
    if (this.elementType === 'masterclass') {
      return (this.elementInfo as IMasterclass)?.speaker?.photoUrl ? (this.elementInfo as IMasterclass).speaker.photoUrl : '../../../../../assets/images/masterclasses/question-mark-ssbu.png';
    } else {
      return (this.elementInfo as IModule).iconUrl;
    }
  }

  openElementFormDialog(): void {
    this.dialog
      .open(this.backOfficeELearningService.determineElementFormComponent(this.elementType) as any, {
        width: '900px',
        panelClass: 'new-element-form-dialog',
        data: {
          elementInfo: this.elementInfo
        }
      })
      .afterClosed()
      .subscribe((dialogResult: { element: IELearningElement; operation: ELearningElementDialogOperation }) => {
        if (dialogResult) {
          if (dialogResult.operation !== 'delete') {
            if (this.elementInfo) {
              if (dialogResult.element.type === 'courseContent') {
                this.createNewElement.emit(dialogResult.element);
              } else {
                this.elementInfoChange.emit(dialogResult.element);
              }
            } else {
              this.createNewElement.emit(dialogResult.element);
            }
            if (dialogResult.operation === 'open') {
              this.openNewElement.emit(dialogResult.element);
            }
          } else {
            this.deleteElement.emit({ info: this.elementInfo, type: this.elementType });
          }
        }
      });
  }

  changePosition(position: number) {
    this.changeIndex.emit(position);
  }

  getElementInfoAsCourse(): ICourse {
    return this.elementInfo as ICourse;
  }
}
